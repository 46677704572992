<template >
<div :id="`draggable-item-${id}`">
	<div :class="'draggable-item todo-board-card '+extclass">
		<div class="item-body">
			<div> <small>Project > List</small>
				<h5>{{title}}</h5>
				<p class="text-ellipsis short-1 mb-0 mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
			<div>
				<div class="rounded">
					<a href="#" v-b-tooltip.hover title="Assign To">
						<svg width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
					</a>
				</div>
			</div>
		</div>
		<div class="item-footer">
			<div class="d-flex justify-content-between align-items-center">
				<div class="d-flex">
					<div class="pr-3">
						<a href="#" class="text-primary" v-b-tooltip.hover title="Due Date">
							<svg class="svg-icon" width="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /> </svg>
						</a>
					</div>
					<div class="pr-3">
						<a href="#" class="text-primary" v-b-tooltip.hover title="Set Priority">
							<svg class="svg-icon" width="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" /> </svg>
						</a>
					</div>
					<div class="pr-3">
						<a href="#" class="text-primary" v-b-tooltip.hover title="Tag">
							<svg class="svg-icon" width="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" /> </svg>
						</a>
					</div>
					<div class="pr-3">
						<a href="#" class="text-primary" v-b-tooltip.hover title="Estimated Time">
							<svg class="svg-icon" width="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg>
						</a>
					</div>
				</div>
				<b-dropdown id="dropdownMenuButton1" right variant="none p-0" data-toggle="dropdown" class="dropdown shadow-none" no-caret>
					<template v-slot:button-content>
						<a href="#" class="text-primary pl-3" id="dropdownMenuButton-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<svg class="svg-icon" width="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" /> </svg>
						</a>
					</template>
					<b-dropdown-item>
						<svg class="svg-icon text-primary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /> </svg> {{ (' Edit') }} </b-dropdown-item>
					<b-dropdown-item>
						<svg class="svg-icon text-primary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /> </svg> {{ ('View') }} </b-dropdown-item>
					<b-dropdown-item>
						<svg class="svg-icon text-primary" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /> </svg> {{ ('Delete') }} </b-dropdown-item>
				</b-dropdown>
			</div>
		</div>
	</div>
</div>
</template>
<script>
export default {
    name:'todolistcard',
    props:{
      id:{type:String,default:''},
     extclass:{type:String,default:''},
     title:{type:String,default:''},
    }
}
</script>